import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent implements OnInit {
  public title: string;

  constructor(private appService: AppService) {
    this.title = this.appService.heading;
  }

  ngOnInit(): void {}
}
