<header>
  <div class="main">
    <div class="container inner">
      <img width="180px" height="50" src="assets/logos/logo.png" alt="Logo" />
      <h3 class="title">{{title}}</h3>
    </div>
  </div>
  <div class="container">
    <div class="header-secondary">
      <span *ngIf="viewpoint && !viewpoint.main" class="item numberplate">
        <span [innerHTML]="viewpoint.roadsign.title"></span>
        <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
      </span>
    </div>
  </div>
</header>
<app-back-button *ngIf="viewpoint" [pageName]=" "></app-back-button>