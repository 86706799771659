import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AppComponent } from './app.component';
import { ViewpointComponent } from './components/pages/viewpoint/viewpoint.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SharedModule } from '@shared/components/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MapComponent } from '@pages/map/map.component';
import { AngularSplitModule } from 'angular-split';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewpointComponent,
    MapComponent,
    ComingSoonComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    IvyCarouselModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SharedModule,
    AngularSplitModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
