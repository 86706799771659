<div>
  <div>
    <img class="side-image" src="assets/images/coming-soon.png" alt="title" />
    <h1 class="title">{{ title | uppercase }}</h1>
    <img class="logo" src="assets/logos/truescape-logo-light.png" />
  </div>
  <div class="message">
    <p>Thanks for visiting, this project is</p>
    <h1>COMING SOON</h1>
  </div>
</div>
