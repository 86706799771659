import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComingSoonComponent } from '@pages/coming-soon/coming-soon.component';
import { MapComponent } from '@pages/map/map.component';
import { ViewpointComponent } from '@pages/viewpoint/viewpoint.component';
import { app as appConfig } from '@data/project.data.json';

const displayComingSoonPage = appConfig.displayComingSoonPage;

const basicRoutes: Routes = [
  { path: 'map', component: MapComponent },
  { path: 'viewpoint', component: ViewpointComponent },
];

const defaultRoute = displayComingSoonPage
  ? { path: '', component: ComingSoonComponent, pathMatch: 'full' }
  : { path: '', redirectTo: '/map', pathMatch: 'full' };

const routes: Routes = [defaultRoute, ...basicRoutes];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
